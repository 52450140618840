import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Mercurium Finance',
  description:
    'The most popular AMM on BSC by user count! Earn Mercurium through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Mercurium Finance), NFTs, and more, on a platform you can trust.',
  image: 'https://mercuriumswap.finance/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Mercurium Finance')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Mercurium Finance')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Mercurium Finance')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Mercurium Finance')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Mercurium Finance')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Mercurium Finance')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Mercurium Finance')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Mercurium Finance')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Mercurium Finance')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Mercurium Finance')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Mercurium Finance')}`,
      }
    default:
      return null
  }
}
